<template>
  <header>
    <nav class="flex items-center justify-between flex-wrap bg-teal-500 p-6">
      <div class="flex items-center flex-shrink-0 text-white mr-6">
        <span class="font-semibold text-xl tracking-tight">EO trainingen</span>
      </div>
      <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div class="text-sm lg:flex-grow">
          <NuxtLink class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4" to="/trainingen">Home</NuxtLink>
        </div>
      </div>
    </nav>
  </header>
  <div class="flex h-screen flex-col m-8">
    <slot />
  </div>
</template>
